.custom-navbar {
  background-color: #F5F5F5;
  align-items: baseline;
}

.custom-navbar .thinkficial-logo{
  height: 40px;
  margin-left: 10px;
}  

.custom-navbar .nav-links-container ul{
list-style-type: none;
display: flex;
width: 100%;
justify-content: space-between;
font-weight: 600;
padding: 0;
}

.custom-navbar ul li .nav-link:hover{
cursor: pointer;
border-bottom: 5px solid rgba(rgba(3, 4, 94));
}

.blue-bg{
background-color: rgba(rgba(3, 4, 94));
}
.isActive{
color: green;
border-bottom: 5px solid lightblue;
}

@media only screen and (max-width: 995px) {
.custom-navbar {
  width: 100%;
  z-index: 100;
  min-height: 50px;
}

.custom-navbar .nav-links-container ul{
  display: block;
  text-align: center;
  padding-top: 20px;
}
.custom-navbar .thinkficial-logo{
  margin: 0;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 15px;

}
.hamburger-toggler{
  position: absolute;
  top: 5px;
  right: 15px;
}

}