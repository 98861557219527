.top-button{
    color:  rgb(3, 4, 94);
    background-color: white;
    font-size: 20px;
    border: 5px solid rgb(3, 4, 94);
    border-radius: 50%;
    padding: 5px;
    position: fixed;
    right: 2px;
    bottom: 5px;
    display: block;
    z-index: 100;
}

.hide-top-nav-link{
    display: none;    
}

.top-button:hover{
    cursor: pointer;
}