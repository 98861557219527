@import '~bootstrap/dist/css/bootstrap.min.css';
*{
  padding: 0;
  margin: 0;
  font-family: 'Montserrate',sans-serif;
}
body{
  overflow-x: hidden;    
}

section{
    text-align: center;
  }

 .section-container{
  margin-top: 40px;
 }

 .section-heading{
  margin: 20px auto;
  font-size: 100px;
}

  .index-container{
    margin: 0;
    padding: 0;  
    align-items: center;
    justify-content: center;
  }

  .home-page{
    margin: 0;
    height: 90vh;
    background-image: url('./assets/images/hero-image.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .overlay {
    position: relative;
    background-color: rgba(3, 4, 94, 0.7);
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
.home-content{
    width: 75%;
}

.home-heading{
  font-size: 35px;
  font-weight: bolder;
  line-height: 1.6em;
}

.home-content p{
  font-size: 1.6rem;
  line-height: 2.5rem;
  text-align: justify;
}



.contact-button {
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: -40px;
  background-color: #f5f5f5;
  color: rgba(3, 4, 94);
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: none; 
  border-radius: 5px; 
  cursor: pointer;
  font-weight: 700;
  font-size: 1.5rem;
}

.contact-button:hover {
  background-color: rgba(3, 4, 94);
  color: #f5f5f5 ;
}

.top-logo {
  position: absolute;
  width: 170px;
  height: 170px;
  top: 38px;
  left: 0px;
}

.bottom-logo {
  position: absolute;
  width: 170px;
  height: 170px;
  bottom: 0;
  right: 0;   
}

.read-more{
  color: #f5f5f5;
  cursor: pointer;
  text-decoration: underline;
}

/*SERVICES*/
#services .section-description{
  font-weight: 400;
}


#service-detail-card{
  margin-bottom: 20px;
  width: 90%;
  box-shadow: 0 14px 8px rgba(0, 0, 0, 0.1);
}

#service-detail-card h3{
  font-size: 24px;
  font-weight: 400;
}


.section-heading{
  font-weight: 900;
  font-size: 40px;
  padding: 20px;
}

.section-description{
  margin: 20px;
  font-size: 25px;
  font-weight: 400;
}



.contact-container{
  margin-top: 20px;
}

.form-input{
  margin-bottom: 20px;
}


/* MOBILE AND TABLET DESIGN OF HOME PAGE */

@media only screen and (max-width:995px) {
  .home-content{
    width: 90%;
  }
  .top-logo, .bottom-logo{
    display: none;
  }
}


@media only screen and (max-width: 767px) {
  #home .home-content {
    width:90%;
    margin-top: -50px;
  }
  .home-content h2{
    font-size: 1.4em;
  }
  .home-content p{
    font-size: 1.2em;
  }

}
@media only screen and (max-width: 402px) {
  .home-content {
    width:90%;
    margin: 0;
  }
  .home-content h2{
    font-size: 1.2em;
  }
  .home-content p{
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

@media only screen and (max-width: 263px) {
  .home-content {
    width:95%;
    margin: 0;
  }
  .home-content h2{
    font-size: 18px;
  }
  .home-content p{
    font-size: 18px;
    line-height: 1.1em;
  }
}
/*CONTACT SECTION*/
#contact .section-heading{
  line-height:2em;
  font-size: 1.6em;
}

.contactForm{
  justify-content:space-between;
}
