section.footer{
    margin: 0;
    padding: 0;
    color: #f5f5f5;
    width: 100%;
    position: relative;
    left: 0;
    background-color: rgba(3, 4, 94);
    font-size: 20px;
    padding: 5px;
    margin-top: 25px;
}

.footer-container {
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
 }

  .social-icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
 .social-icon{
  margin-right: 10px;
  color: #f5f5f5;
 }
  
.social-icon:hover{
  border-radius: 50%;
  background-color: #f5f5f5;
  color: rgba(3, 4, 94);
  width: 30px;
}

@media only screen and (max-width: 760px) {
  .footer-container{
    max-width: 100%;
    display: block;
    text-align: center;
    font-size: 25px;
  }
  .social-icons-container{
    width: 40%;
    display: flex;
    text-align: center; 
    margin: auto 30%;
    justify-content: center;
    font-size: 30px;
  }
}