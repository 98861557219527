

 section.about{
    text-align: justify;
    margin-top: 15px;
}
.about-header{
    display: flex;
    color: rgba(3, 4, 94);
}
.about-header .about-heading{
    margin: 0 auto;
    font-size: 2.5em;
    font-weight: 600;
}
.about-header .previous-icon{
    color: rgba(3, 4, 94);
    margin-left: 2em;
    font-size: 30px;    
}
.about-content-container{
    padding: 20px;
    font-size: 18px;
}
.about-content-container .about-content .mission h3, .about-content-container .about-content .vision h3{
    margin: 20px 0;
    font-size: 2em;
    font-weight: 600;
    color: rgba(3, 4, 94);
}

@media only screen and (max-width: 300px) {
    .about-header{
        display: block;
        text-align: center;
    }
    .about-header .previous-icon, .about-header .about-heading{
        display: block;
        margin: 0 auto;
    }

    .about-content-container .about-content .mission h3, .about-content-container .about-content .vision h3{
        font-size: 1.5em;
    }
}