.divider-container{
    display: flex;
    margin-top: 50px;
    justify-content: center;
}

.divider-container .divider{
    margin-right: 4px;
    width: 2em;
    background-color: rgb(204, 196, 196);
    height: 10px;
    border-radius: 10px;
}

.divider-container .divider.middle-divider{
    width: 5.5em;
}