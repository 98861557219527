.member-role{
    display:block;
    font-size: 20px;
    font-weight:800;
}

.member-details{
    padding-top: 30px;
}

.member-details .member-name{
    padding-top: 10px;
    font-size: 15px;
    font-weight: 700;
}
.member-image{
    border-radius: 50%;
    width: 250px;
    height: 250px;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .member-details .member-image{
        width: 210px;
        height: 210px;
    }
}
@media only screen and (max-width: 995px) {
    #team .section-heading{
        font-size: 3em;
    }
    .member-details .member-image{
        width: 200px;
        height: 200px;
    }

}
@media only screen and (max-width: 767px) {
    #team .section-heading{
        font-size: 2em;
    }
    #team .section-description{
        width: 90%;
        font-weight: 400;
        text-align: justify;
        align-items: center;
        font-size: 1.1rem;
    }
    .member-details .member-image{
        width: 300px;
        height: 300px;
    }
    .member-details .member-name{
        font-size: 20px;
    }
    .member-details .member-role{
        font-size: 18px;
        font-weight: 700;
    }

}

@media only screen and (max-width: 415px) {
    #team .section-heading{
        font-size: 1.2em;
    }
    #team .section-description{
        font-size: 16px;
        padding: 0;

    }
    .member-details{
        padding-top: 15px;
    }
    .member-details .member-image{
        align-self: center;
        width: 200px;
        height: 200px;
    }
    .member-details .member-name{
        font-size: 1.6rem;
    }
    
}