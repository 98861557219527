#services .section-centent{
    justify-content: center;
}

#services .section-centent #service-detail-card{
    width: 220px;
    margin: 15px auto;
    height: 350px;
}
#services .section-centent .section-description{
    width: 90%;
    font-size: 1.2em;
}
#services .section-centent #service-detail-card .card-body .service-detail-description{
    font-size: 16px;
    text-align: justify;
}

#services .section-centent #service-detail-card .card-body .card-title{
    font-size: 1.1em;
}
#services .section-centent #service-detail-card .service-detail-card-img{
    width: 160px;
    height: 160px;
    margin: 8px auto;
    align-items: center;
}
@media only screen and (max-width: 995px) {
    #services .section-centent #service-detail-card{
        width: 90%;
    }
}
@media only screen and (max-width: 767px) {
    #services .section-centent #service-detail-card{
        width: 80%;
        height: 400px;
    }
    #services .section-centent #service-detail-card .card-body .service-detail-description{
        font-size: 1.3em;
    }
    #services .section-centent #service-detail-card .card-body .card-title{
        font-size: 1.2em;
        margin-bottom: 10px;
    }
    #services .section-centent #service-detail-card .service-detail-card-img{
        width: 250px;
        height: 190px;
        margin:0 auto;
    }
}
@media only screen and (max-width: 402px) {
    #services .section-centent #service-detail-card{
        width: 95%;
        height: 400px;
    }
    #services .section-centent #service-detail-card .service-detail-card-img{
        width: 150px;
        height: 150px;
        margin:0 auto;
    }
    #services .section-centent #service-detail-card .card-body .service-detail-description{
        font-size: 1.1em;
    }
}
