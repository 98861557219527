
.testimony{
    font-size: 18px;
    padding: 10px;
    margin: 15px 0px;
    text-align: justify;
    justify-content: center; 
}


@media only screen and (max-width: 995px) {
    .testimonies-page .section-heading{
        font-size: 2.1em;
    }
    .testimonies-page .section-description{
        font-size: 1.4em;
        width: 100%;        
    }
}

@media only screen and (max-width: 767px) {
    .testimonies-page .section-heading{
        font-size: 1.2rem;
        text-transform: uppercase;
    }
    .testimonies-page .section-description{
        font-size: 1.2em;
        width: 85%; 
        text-align: justify;       
    }
}
@media only screen and (max-width: 402px) {
    .testimonies-page .section-heading{
        font-size: 0.9rem;
        text-transform: uppercase;
    }
    .testimonies-page .section-description{
        font-size: 0.9em;
        width: 80%; 
        text-align: justify;       
    }
    .testimony-comment{
        font-size: 16px;
    }
}