.not-found-container{
    height: 70vh;
    padding: 40px 0px;
}

.not-found-container h3, .not-found-container p, .not-found-container .home-link{
    font-size: 3em;
}
.not-found-container .home-link{
  background-color: rgb(5, 5, 76);
  color: #f5f5f5;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

.custom-navbar ul{
    display: block;
    text-align: center;
    padding-top: 20px;
  }