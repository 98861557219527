
.custom-row {
  margin-left: -8px; 
  margin-right: -8px;

}

.custom-col {
  padding-left: 18px;
  padding-right: 18px; 
  text-decoration: none;
  margin-bottom: 20px;
}

.course-detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
}
.course-detail button{
  border: none;
  background-color: rgb(5, 5, 76);
  color: #f5f5f5;
  height: 40px;
  border-radius: 5px;
  width: 120px;
} 

.course-detail span{
  color: rgb(5, 5, 76);
  font-weight: 500;
}
.left-element {
 height: 70px;
 border-left: 10px solid lightblue;
 border-right: 1px solid lightblue;
 border-bottom-right-radius: 20px;
 border-bottom-left-radius: 20px;
 border-top-left-radius: 20px;
 border-top: 1px solid lightblue;
 box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.right-element { 
  height: 65px;
  border-right: 10px solid lightblue;
  border-left: 1px solid lightblue;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: 1px solid lightblue;
  border-top-right-radius: 20px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}

.academy .section-heading{
  font-size: 2em;
}

.academy .academy-header{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.academy .previous-icon{
  font-size: 3em;
  color: rgb(5, 5, 76);
}

.academy .get-in-touch-button{
  background-color: #f5f5f5;
   border:1px solid lightblue; 
   color:rgba(3, 4, 94);
   font-weight:'700';
   box-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
   border-radius:5px;
   height: 35px;
   text-decoration:none
}
.academy .course-duration {
  display: inline-block;
  color: #f5f5f5;
  background-color: rgba(3, 4, 94);
  text-decoration: none; 
  border-radius: 5px; 
  cursor: pointer;
  height: 50px;
  width: 120px;
  font-size: 1.2em;
  padding: 10px 5px;
}
.course-detail .course-title{
  padding-left: 5px;
}
/* RESPONSIVE DESIGN */
@media only screen and (max-width: 995px) {
  
  .course-detail .course-duration{
    font-size: 16px;
    width: 90px;
    height: 40px;
    font-weight: 400;
    padding: 5px 2px;
  }
  .course-detail .course-title{
    font-size: 10px;
    font-weight: 800;
    line-height: 10;
  }
}

@media only screen and (max-width: 767px) {
  .course-detail .course-title{
    font-size: 14px;
  }
  .course-detail .course-duration{
    width: 150px;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    font-weight: 600;
  }

 .course-detail { 
   height: 80px;
   border-right: 10px solid lightblue;
   border-left: 1px solid lightblue;
   border-bottom-right-radius: 20px;
   border-bottom-left-radius: 20px;
   border-top-left-radius: 0px;
   border-top: 1px solid lightblue;
   border-top-right-radius: 20px;
   box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
 }  
}

@media only screen and (max-width:500px){
  .course-detail{
    height: 100px;
    width: 100%;
  }
  .course-detail .course-title{
    display: block;
    font-size: 12px;
    font-weight: 600;
  }
  .course-detail .course-duration{
    font-weight: 600;
    font-size: 13px;
    width: 100px;
  }
} 

@media only screen and (max-width:360px){
  .custom-col { 
    display: block;
    margin-bottom: 70px;
    height: 150px;
  }  
  .course-detail {
    height:auto;
    display: block;
  }
  
  .course-detail .course-title{
    font-size: 10px;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    width: 100%;
    position: relative;
    top: -30px;
  }
  .course-detail .course-duration{
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    position: relative;
    top: -40px;
    height: 80px;
  }
  
}